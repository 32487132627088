<template>
  <v-menu
    v-model="menu"
    offset-y
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">mdi-clock</v-icon>
    </template>
    <v-time-picker format="24hr" v-model="picker"> </v-time-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    picker: {
      get() {
        return this.value;
      },
      set(val) {
        this.menu = false;
        this.$emit("input", val);
      },
    },
  },
};
</script>
